
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState, Suspense } from "react";
import Head from 'next/head';
import { connect, useDispatch, useSelector } from "react-redux";
const Board = React.lazy(() => import('../../../../../components/Board/board'));
import * as gameDataAction from "../../../../../store/gameData/gameDataActions";
import GameModal from "../../../../../components/GameModal/GameModal";
import WithAuthorizationGuard from "../../../../../hoc/withAuthorizationGuard";
import GameStats from "../../../../../components/GameStats/GameStats";
import PredefinedChat from "../../../../../components/Chat/PredefinedChat";
import { toggleSidebar, setUserForProfileSidebar } from "../../../../../store/modals/modalsActions";
import * as styles from "../../../../../styles/scss/pages/gamePage/gamePage.module.scss";
import Loader from "../../../../../components/Loader/Loader";
import { Howl } from "howler";
import dealingSound from "../../../../../sounds/dealing_cards.mp3";
import stealingSound from "../../../../../sounds/stealing.mp3";
import dropSound from "../../../../../sounds/dropcards.mp3";
import myTurnSound from '../../../../../sounds/myturn.mp3';
import api from "../../../../../axios/api";
import { RematchModal } from "../../../../../components/RematchModal";
import { GradientButtons } from "../../../../../components/Buttons/Buttons";
const Card = React.lazy(() => import('../../../../../components/Card/Card'))
import NoCardsModal from '../../../../../components/NoCardsModal/NoCardsModal';
import { friendRequest } from "../../../../../store/friends/friendActions";
import GameRulesSvg from '../../../../../public/images/common/game_rules.svg';
import SettingsSvg from '../../../../../public/images/common/settings.svg';
import Avatar from "../../../../../components/Avatar/Avatar";
import Countdown from "../../../../../components/Countdown/CountDown";
import { setOfflineModalState, buzzOfflineModal } from "../../../../../store/modals/modalsActions";
import { FeedbackModal } from "../../../../../components/GameModal/FeedbackModal";
import { handleReturnAsAdmin } from "../../../../../helpers/Impersonate";
import { deviceType, getMobileOS } from "../../../../../helpers/functions";
import Backdrop from '../../../../../components/Backdrop/Backdrop.js';
import gsap from 'gsap';
import { updatePlayerFriendshipStatus } from "../../../../../store/gameData/gameDataActions";
import { useComponentVisible } from "../../../../../helpers/hooks";
import useTranslation from 'next-translate/useTranslation'
import SyncGameLoader from "./SyncGameLoader";
import OfflineModal from "../../../../../components/OfflineModal";
import { resetMm } from "../../../../../store/matchMaking/matchMakingActions";
import { resetPublicLobby } from "../../../../../store/lobby/publicLobby/lobbyActions";
import OtherPlayersIndicator from "../../../../../components/OtherPlayersIndicator";
import GamepageHeader from "../../../../../components/Header/GamepageHeader";
import { sendStatsToBackend } from '../../../../../store/static/staticInfoActions';
import { ConnectionErrorPopup } from '../../../../../components/Modal/ConnectionErrorPopup';
import { saveSettings } from '../../../../../store/settings/settingsActions';
import { imageVersion, cdnUrl } from '../../../../../config/imageVersion.config';

const Game = (props) => {

  const {
    onGetGameData,
    gameData,
    onCardExchangeHandler,
    user,
    onGameCommontCardEvent,
    onSetCardPlayAction,
    onHandleCardPlay,
    onChangePlayerTurn,
    onHandleCardSteal,
    onSetPlayersForStealing,
    onSetNewRoundData,
    onDropCards,
    setCanPlayStatusToCards,
    onSetPLayersReadyForExchange,
    onMarbleMove,
    onMarbleKill,
    onMarbleSwap,
    socketId,
    setRematchApproval,
    onSetPlayerMessage,
    onToggleSidebar,
    onGameFinish,
    onSetRecentAction,
    onSetDroppedCards,
    onSetChosenVictim,
    isAuthenticated,
    friendsList,
    onUpdateScoreboard,
    onFriendRequest,
    onSetUserForProfileSidebar,
    activeBoardTheme,
    onResetGameData,
    isSoundOn,
    onUpdatePlayerFriendshipStatus,
    onSendStatToBackend
  } = props;

  console.log('props: ', props)

  const router = useRouter();
  const {t} = useTranslation();

  const [gamePrivateActionChannel, setGamePrivateActionChannel] = useState(null);

  const [lobbyChanel, setLobbyChannel] = useState(null);
  const [gamePublicActionChannel, setPublicGameActionChannel] = useState(null);
  const [chatChannel, setChatChannel] = useState(null);
  const [newRoundData, setNewRoundData] = useState({});
  const [showSyncLoader, setShowSyncLoader] = useState(false)
  const [shouldGameReload, setShouldGameReload] = useState(false)
  const [modalState, setModalState] = useState(false);
  const [modalFor, setModalFor] = useState();
  const [openBoardCardsModule, setOpenBoardCardsModule] = useState(false);
  const [openReplaySevenModule, setOpenReplaySevenModule] = useState(false);
  const [toggleChat, setToggleChat] = useState(false);
  const [showCountDown, setShowCountdown] = useState(false);
  const [delayIsActicve, setDelayIsActive] = useState(false);
  const [rematchModal, setRematchModal] = useState(false);
  const [showPyro, setShowPyro] = useState(false)
  const [canGoToOpponent, setCanGoToOpponent] = useState({});
  const [isJoystick, setIsJoystick] = useState(false);
  const [toggleFullscreen, setToggleFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true)
  const [showOfflineModal, setSHowOfflineModal] = useState(false)
  const [isInfoOpen ,setIsInfoOpen] = useState(false);
  const [playerOfflineModalTitle, setPlayerOfflineModalTitle] = useState('inactive')

  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [playActionSound, setPlayActionSound] = useState(null)
  const [isIos, setIsIos] = useState(false)
  const [offlinePlayers, setOfflinePLayers] = useState(null)
  const [stealingInProgress,setStealingInProgress] = useState(false)
  const [feedbackState, setFeedbackState] = useState({
    show: false,
    showInput: false,
    issueType: '',
    comment: '',
    loading: false,
    gameType: ''
  })

  const cardStyle = useSelector(state => state.settings.cardStyle)

  const {isRefreshPopupShow} = useSelector(state => state.toggles)

  const showCardStyleSelectorModal = useSelector((state) => state.settings.showCardStyleSelectorModal == 1);

  const [cardStyleSelector, setCardStyleSelector] = useState(true);

  // const cardStyle = {id: 'classic'}
  const {
    ref: notifRef,
    isComponentVisible: isNotifOpen,
    setIsComponentVisible: setIsNotifOpen
  } = useComponentVisible(false);
  
  const {
    ref: menuRef,
    isComponentVisible: isMenuOpen,
    setIsComponentVisible: setIsMenuOpen
  } = useComponentVisible(false);

  const gamePageRef = useRef();

  const dispatch = useDispatch()
  const handleOpenOfflineModalState = (state) => {
    dispatch(setOfflineModalState(state))
  }
  const buzzModalHandler = (state) => {
    dispatch(buzzOfflineModal(state))
  }

  const toggleIssueType = (type) => {
    setFeedbackState(prevState => ({...prevState, issueType: type, showInput: true}));
  }

  const handleCommentChange = (e) => {
    setFeedbackState(prevState => ({...prevState, comment: e.target.value}))
  }

  const handleFeedbackSubmit = async () => {
    setFeedbackState(prevState => ({...prevState, loading: true}))
    api.post('feedback', {gameUuid: router.query.gameId, issue: feedbackState.issueType, comment: feedbackState.comment}).then(async (res) => {
      setFeedbackState(prevState => ({loading: false, show: false, issueType: '', showInput: false, comment: ''}))
      // window.location.href = `/${router.locale}`
      await api.post(`/lobby/${router.query.lobbyId}/game/${router.query.gameId}/leave`)
      window.location.href = `/${router.locale}`
    }).catch(async () => {
      setFeedbackState(prevState => ({loading: false, show: false, issueType: '', showInput: false, comment: ''}))
      // window.location.href = `/${router.locale}`
      await api.post(`/lobby/${router.query.lobbyId}/game/${router.query.gameId}/leave`)
      window.location.href = `/${router.locale}`
    } );
  }

  const handleFeedbackClose = () => {
    setFeedbackState(prevState => ({
      show: false,
      showInput: false,
      issueType: '',
      comment: '',
      loading: false,
      gameType: prevState.gameType
    }))
  }

  const handleModalStateChange = useCallback((state) => {
    if (state === undefined) {
      setModalState((oldState) => !oldState);
      return;
    }
    if (state === false) {
      setModalFor(null)
    }
    // onToggleBackdropState(state)
    setToggleBackdrop(state)
    setModalState(state);
  }, []);

  const dropCardsHandler = useCallback((playerId) => {
    onDropCards(playerId);
    setShowCountdown(false);
  }, []);

  const handleBoardCardsModalStateChange = useCallback((state) => {
    setOpenBoardCardsModule(state);
  }, []);

  useEffect(() => {
    setIsMobile(deviceType() === 'mobile')
    setIsIos(getMobileOS() === 'ios')
    getMobileOS()
    return () => {onResetGameData()}
  }, [])

  const syncGameData =  async () => {
    if (document.visibilityState === 'visible' && router.pathname === '/lobby/[lobbyId]/game/[gameId]') {
      setShowSyncLoader(true)
      if (isAuthenticated) {
        // debugger;
        const requestData = {
          payload: {
            gameId: router.query.gameId,
            lobbyId: router.query.lobbyId,
          },
          socketId: socketId,
          resetMarbles: true
        }
        const response = await onGetGameData(requestData);
        setShowSyncLoader(false)
        if (response?.response?.data?.message === 'WRONG_GAME') {
          router.replace('/')
        }
      }
  
    }
  }

  useEffect(() => {
    window.pusher?.connection.bind('state_change', state => {
      if (state.previous === 'connected') {
        setShouldGameReload(true)
      } else if (state.current === 'connected' && shouldGameReload) {
        setShouldGameReload(false)
        handleOpenOfflineModalState(false)
        location.reload()
      }
    })
  }, [window.pusher, shouldGameReload])

  // const dropCardsHandler =  (playerId) => {
  //   onDropCards(playerId);
  //   setShowCountdown(false);
  //   // setDropped(true);
  // }

  useEffect(() => {
    if (playActionSound) {
      try {
        const sound = new Howl({
          src: playActionSound,
          html5: true,
        });
        if (isSoundOn) {
          sound.play();
        } else{
          sound.mute(false)
        }
        setPlayActionSound(null)  
      } finally {}
    }
  }, [playActionSound, isSoundOn])

  useEffect(() => {
    const func = async () => {
      if (isAuthenticated) {
        // debugger;
        const requestData = {
          payload: {
            gameId: router.query.gameId,
            lobbyId: router.query.lobbyId,
          },
          socketId: socketId
        }
        setToggleBackdrop(false)
        const response = await onGetGameData(requestData);
        if (response?.response?.data?.message === 'WRONG_GAME') {
          router.replace('/')
          return
        } else if (response?.response?.data?.message === 'JOIN_GAME_AS') {
          router.push(`/inspector/?tab=preview&gameId=${router.query.gameId}`)
          return
          // router.replace('/')
        }
        document.addEventListener('visibilitychange', syncGameData)
      }
    }
    func();
    return () => {document.removeEventListener('visibilitychange', syncGameData)}
  }, [isAuthenticated]);

  useEffect(() => {
    // debugger;
    if (!gameData.playerIsInTheGame) {
      // router.replace("/");
    }
  }, [gameData.playerIsInTheGame]);

  useEffect(() => {
    if (!!gameData.isGameDataLoaded && !!window.pusher && !!user) {
      if (gameData.forceFinished) {
        setSHowOfflineModal(true)
        setPlayerOfflineModalTitle(gameData.leavers[0].status)
        setOfflinePLayers(gameData.leavers)
      }

      setFeedbackState(prevState => ({...prevState, gameType: gameData.type}))

      let gamePrivateActionChannel = window.pusher.subscribe(
        `private-Game.${router.query.gameId}.${user.id}`
      ); //game.actions for players which are part of actions
      let gamePublicActionChannel = window.pusher.subscribe(
        `private-Game.${router.query.gameId}`
      ); //game.actions for players which are not part of actions
      let gameLobbyChannel = window.pusher.subscribe(
        `private-Lobby.${router.query.lobbyId}`
      );

      window.pusher.unsubscribe(`Chat.${gameData.chat.id}`);
      let chatChannel = window.pusher.subscribe(`private-Chat.${gameData.chat.id}`);
      setLobbyChannel(gameLobbyChannel);
      setGamePrivateActionChannel(gamePrivateActionChannel);
      setPublicGameActionChannel(gamePublicActionChannel);
      setChatChannel(chatChannel);

      return () => {
        window.pusher.unsubscribe(`private-Game.${router.query.gameId}.${user.id}`);
        window.pusher.unsubscribe(`private-Game.${router.query.gameId}`);
      };
    }
  }, [gameData.isGameDataLoaded, user, window.pusher]);

  React.useLayoutEffect(() => {
    if (!!gamePrivateActionChannel) {
      gamePrivateActionChannel.bind("game.action", (data) => {
        if (data.action.type === "exchangeCard") {
          const exchangeData = {
            actionPlayer: { ...data.action.actionPlayer },
            targetPlayer: { ...data.action.targetPlayer },
            card: { ...data.action.card },
          };
          onCardExchangeHandler(exchangeData);
        } else if (data.action.type === "stealCard") {
          gsap.to(".victimPlayer .playerCard > div", { 
            y: 0, 
            scale: 1, 
            duration: .3 
          });
          setPlayActionSound(stealingSound)
          // playSound(stealingSound);
          handleModalStateChange(false);
          onHandleCardSteal(data.action);
        } else if (data.action.type === "dealCards") {
          const player = { ...data.action };
          setNewRoundData((oldData) => {
            const cardData = oldData.cardData ? [...oldData.cardData] : [];
            return {
              ...oldData,
              cardData: [...cardData, player],
            };
          });
        } else if (data.action.type === "changeTurn") {
          gsap.delayedCall(1, () => {
            const changeTurnObj = {
              nextPlayer: { ...data.action.targetPlayer },
              playerCards: [...data.action.cards],
            };
            onChangePlayerTurn(changeTurnObj);
            setStealingInProgress(false)
          })
          handleModalStateChange(false);

          if (data.action.targetPlayer.user.id === user.id) {
            setPlayActionSound(myTurnSound);
          }
        }
      });
    }
  }, [gamePrivateActionChannel]);

  useEffect(() => {
    if (!!lobbyChanel) {
      lobbyChanel.bind("start.game", (data) => {
        setRematchModal(false);
        window.location.href = `/${router.locale}/lobby/${data.lobbyId}/game/${data.gameId}`;
      });
    }
  }, [lobbyChanel]);

  React.useLayoutEffect(() => {
    if (!!gamePublicActionChannel) {
      gamePublicActionChannel.bind("game.action", (data) => {
        if (data.action.type === "playCard") {

          const playedPlayer = gameData.players?.find(player => player.id === data.action.actionPlayer.id);

          // no animation for mobile current player
          if(playedPlayer.position === "bottom" && isMobile){
            return onHandleCardPlay(data.action);
          }

          const cardElement = document.getElementById(`_${data.action.card.id}`);
          const boardCards = document.querySelector(`.boardcard_holder .${playedPlayer.position}`);
          
            let toCenter = {
              x: boardCards.getBoundingClientRect().x, 
              y: boardCards.getBoundingClientRect().y
            }
            if(playedPlayer.position !== "bottom"){
              toCenter.x = boardCards.getBoundingClientRect().x - (cardElement?.parentElement?.parentElement?.getBoundingClientRect().x ?? 0);
              toCenter.y = boardCards.getBoundingClientRect().y - (cardElement?.parentElement?.parentElement?.getBoundingClientRect().y ?? 0);
            }
            
            if(Math.abs(toCenter.x) < 300){
              if(playedPlayer.position === "top"){
                toCenter.x = -400;
                toCenter.y = 400
              }else if(playedPlayer.position === "right"){
                toCenter.x = -400;
                toCenter.y = -400;
              }else if(playedPlayer.position === "left"){
                toCenter.x = 400;
                toCenter.y = 400;
              }
            }
            
            const moveFunction = async () => {
              await tl.to(cardElement.parentElement, 
              {
                rotation: data.action.card.transform || 0,
                left: playedPlayer.position === "bottom" && toCenter.x,
                top: playedPlayer.position === "bottom" && toCenter.y,
                x: playedPlayer.position !== "bottom" && toCenter.x,
                y: playedPlayer.position !== "bottom" && toCenter.y,
                zIndex: 6,
                duration: .1,
                scale: playedPlayer.position === "bottom" ? 'var(--cards-scale)' : 4,
                onComplete: () => {
                  if(cardElement?.parentElement?.parentElement){
                    cardElement.parentElement.parentElement.style.zIndex = 6;
                  }                 
                }
              });

              gsap.delayedCall(.5, () => {
                onHandleCardPlay(data.action);
              })
            }

            let tl = gsap.timeline();
            if(cardElement){
              moveFunction();
            }
          
        } else if (data.action.type === "playerChoosingVictim") {
          const payload = {
            victims: data.action.players,
            stealer: data.action.actionPlayer,
          };
          onSetPlayersForStealing(payload);
          // handleModalStateChange(false)
          // setModalFor(null)
        } else if (data.action.type === "stealCard") {          
          gsap.to(".victimPlayer .playerCard > div", { 
            y: 0, 
            scale: 1, 
            duration: .3 
          });
          setPlayActionSound(stealingSound)
          // playSound(stealingSound);
          onHandleCardSteal(data.action);
          handleModalStateChange(false);
        } else if (data.action.type === "dropCards") {
          // gsap.delayedCall(1, () => {
            setPlayActionSound(dropSound)
            // playSound(dropSound);
            // debugger;
  
            if (gameData.currentPlayer.id !== data.action.targetPlayer.id) {
              // setShowCountdown(true)
              onDropCards(data.action.targetPlayer.id);
            } else {
              setCanPlayStatusToCards({
                playerId: data.action.targetPlayer.id,
                status: false,
              });
              setShowCountdown(true);
            }
          // })
        } else if (data.action.type === "readyToExchange") {
          onSetPLayersReadyForExchange(data.action.actionPlayer.id);
        } else if (data.action.type === "moveMarble") {
          // gsap.delayedCall(0.5, () => {
            data.action.marble.actionPlayer = data.action.actionPlayer.id;
            data.action.marble.moves = data.action.moves;
            onMarbleMove(data.action.marble);
          // })
        } else if (data.action.type === "killMarbles") {
          // gsap.delayedCall(0.5, () => {
            onMarbleKill(data.action.marbles);
          // })
        } else if (data.action.type === "swapMarbles") {
          // gsap.delayedCall(0.5, () => {
            onMarbleSwap(data.action);
          // })
        } else if (data.action.type === "endGame") {
          onGameFinish(data);
        } else if (data.action.type === 'updateScoreboard') {
          const payload = {
            playerId: data.action.actionPlayer.id,
            score: {
              ...data.action.score
            }
          }
         onUpdateScoreboard(payload)
        } 
        else if (data.action.type === 'playerInactive') {
          const obj = {
            id: data.action.actionPlayer.id,
            reason: data.action.reason
          }
          dispatch(gameDataAction.updatePlayerStatus(obj))
        } else if (data.action.type === 'forceEndGame') {
          setOfflinePLayers(data.action.leavers)
          setSHowOfflineModal(true)
          setPlayerOfflineModalTitle(data.action.leavers[0].status)
          dispatch(gameDataAction.setForceFinishedData())
        }
      });
      gamePublicActionChannel.bind("change.round", (data) => {
        gsap.delayedCall(1, () => {
          setPlayActionSound(dealingSound)
          // playSound(dealingSound);
          setNewRoundData((oldData) => {
            return {
              ...oldData,
              roundStarterObj: { ...data },
            };
          });
          setShowCountdown(false);
        })
      });
      gamePublicActionChannel.bind("rematch.requested", (data) => {
        // debugger;
        setRematchApproval(data.player);
      });
      gamePublicActionChannel.bind("recent.action", (data) => {
        // debugger;
        onSetRecentAction(data.action);
        if (data.action.type === "dropCards") {
          onSetDroppedCards(data.action);
        }
      });
      gamePublicActionChannel.bind('client-someoneChangedCard', (data) => {
        const player = gameData.players.find(player => {return player.id === data.userId});
      })
      gamePublicActionChannel.bind('client-playerChoseMarble', (data) => {
        // data is is exact same json object as we described above
        // onGlobalHoleHighligh(data);
        return setCanGoToOpponent(data);
      })
      gamePublicActionChannel.bind("game.checkpoint", (data) => {
        if(data.type === "restore"){
          dispatch(gameDataAction.replaySeven(data.marbles));
          setToggleBackdrop(true);
          setOpenReplaySevenModule(true);
        }
      });
      gamePublicActionChannel.bind('client-choosingVictim', data => {
        gsap.to(".victimPlayer .playerCard > div", { 
          y: 0, 
          scale: 1, 
          duration: .3,
        });
        data.victimId && onSetChosenVictim(data.victimId)
      })
    }
  }, [gamePublicActionChannel]);

  useEffect(() => {
    if (!!chatChannel) {
      chatChannel.bind("chat.message", (data) => {
        if (data.message.userId === gameData.currentPlayer.user.id) {
          setDelayIsActive(true);
        }
        onSetPlayerMessage({
          userId: data.message.userId,
          message: data.message.body,
        });
      });
    }
  }, [chatChannel]);

  useEffect(() => {
    if (Object.keys(newRoundData).length === 2) {
      onSetNewRoundData(newRoundData);
      setNewRoundData(() => {
        return {};
      });
    }
  }, [newRoundData]);

  useEffect(() => {
    if (gameData.isPlayingJoker) {
      setModalFor("joker");
      handleModalStateChange(true);
      onSetCardPlayAction("playAs");
    } else {
      handleModalStateChange(false);
    }
  }, [gameData.isPlayingJoker]);

  // const checkVictimsCardsCount = () => {
  //   let cardCount = 0;
  //   for (let i = 0; i < gameData.players.length; i++) {
  //     if (gameData.players[i].victim && gameData.players[i].cards.length > 0) {
  //       for (let j = 0; j < gameData.players[i].cards.length; j++) {
  //         cardCount ++
  //       }
  //     }
  //   }
  //   return cardCount
  // }

  useEffect(() => {
    if (gameData.isPlayingTwo) {
      // debugger;
      // const cardcount = checkVictimsCardsCount()
      // if (cardcount === 0) {
      //   onSetCardPlayAction("playTwo")
      //   return
      // }
      setModalFor("playTwo");
      handleModalStateChange(true);
    } else {
      handleModalStateChange(false);
    }
  }, [gameData.isPlayingTwo]);

  useEffect(() => {
    if (gameData.cardPlayAction === "playTwo") {
      const requestData = {
        gameId: router.query.gameId,
        lobbyId: router.query.lobbyId,
        actionCard: { ...gameData.actionCard },
        payload: {
          type: "playCard",
          cardId: gameData.actionCard.id,
          playAs: "",
          transform: gameData.actionCard.transform,
        },
      };
      // onSetCardPlayAction('');
      if (!socketId || gameData.forceFinished) {
        handleOpenOfflineModalState(true)
        buzzModalHandler(true)
        return
      }
  
      onGameCommontCardEvent(requestData);
    }
  }, [gameData.cardPlayAction, gameData.forceFinished]);

  useEffect(() => {
    if (gameData.roundState === "exchange") {
      setModalFor("exchange");
      handleModalStateChange(true);
      setToggleBackdrop(true);
    }
  }, [gameData.roundState]);

  useEffect(() => {
    /* next from next line you can control backrdop show hide on stealing mode */
    if (gameData.roundState === "exchange") {
      return
    }
    // debugger;
    setToggleBackdrop(gameData.isStealingMode);
    /***************/
    if (gameData.isStealingMode && gameData.choosingVictim) {
      for (let i = 0; i < gameData.victims.length; i++) {
        if (gameData.victims[i].id === gameData.currentPlayer.id && gameData.victims[i].chosenAsVictim) {
          setModalFor("steal");
          handleModalStateChange(true);
          return
        }
      }
      setModalState(false)
    }
  }, [gameData.isStealingMode, gameData.choosingVictim, gameData.victims, gameData.roundState]);

  useEffect(() => {
    // after game finishing rematch modal.
    if (gameData.gameFinished) {
      setRematchModal(true);
    }
  }, [gameData.gameFinished]);

  useEffect(() => {
    // Check if gameData has loaded
    if(!gameData.isLoading){
      setLoading(false);
    }
  }, [gameData.isLoading])

  const fullScreen = () => {
    setToggleFullscreen(state => !state);
    if (document.fullscreenElement || 
      document.webkitFullscreenElement || 
      document.mozFullScreenElement){
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    }else{
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }

  const handleNotificationModalOpen = React.useCallback(() => {
    if (document.body.offsetWidth < 1024) {
      onToggleSidebar({
        sidebarType: 'notification',
        isOpen: true,
        hasTitle: true,
        showSidebarCloseButton: true
      })
    } else {
      setIsNotifOpen(prevState => !prevState)
    }
  }, [])

  const handleHamburgerMenuOpen = React.useCallback(() => {    
    if (document.body.offsetWidth < 1024) {
      onToggleSidebar({
        sidebarType: 'gamePageMenu',
        isOpen: true,
        hasTitle: true,
        showSidebarCloseButton: true,
        sidebarData: {
          handleClick: () => handleLeaveClick()
        }
      })
    } else {
      setIsMenuOpen(prevState => !prevState)
    }
  }, [])

  const rematchGame = (status) => {
    // rematch logic
    return new Promise((resolve, reject) => {
      api
        .post(`lobby/${router.query.lobbyId}/game/${router.query.gameId}/rematch`, {
          agreeOnRematch: status
        })
        .then((response) => {
          // dispatch(gameSevenMarbleEventSuccess(response.data));
          if (!status) {
            setRematchModal((prevState) => !prevState);
            window.location.href = "/" + router.locale;
            return resolve(response.data);
          }
          return resolve(response.data);
        })
        .catch((error) => {
          // dispatch(gameCommonMarbleEventFail(error));
        });
    });
  };

  useEffect(() => {
    if (rematchModal) {
      if (gameData.mode === 'solo') {
        gameData.scoreboard.find((player) => player.won && player.player.id === gameData.currentPlayer.id) ?
        setShowPyro(true) : setShowPyro(false)
      } else {
        gameData.scoreboard.find((player) => player.won && player.player.id === gameData.currentPlayer.id)
        ? setShowPyro(true) : setShowPyro(false)  
      }
    }
  }, [rematchModal])

  const handleCanGoToOpponent = () => {
    setCanGoToOpponent({})
  }

  useEffect(() => {
    checkIfJoystick();
    window.addEventListener('resize', checkIfJoystick);
    return () => {
      window.removeEventListener('resize', checkIfJoystick);
    }
  }, [])

  const checkIfJoystick = () => {
    if (deviceType() === 'mobile') {
      setIsJoystick(true);
    }
  }

  const handleLeaveClick = () => {
    setFeedbackState(prevState => ({...prevState, show: true}));
  }

  const backAsAdminHandler = async () => {
    const response = await handleReturnAsAdmin()
    if (response) {
      window.location.href = '/'
    }
  }

  const footerElement = React.useRef(null);

  const ui_holder_style = () => {
    if(!!gameData?.currentPlayer){
      const { myTurn, playingCard, playingMarble } = gameData?.currentPlayer;
      let className = '';
      if(myTurn){
        if(gameData?.isStealingMode || playingMarble){
          className = 'playingMarble';
        }else if(playingCard){
          className = 'playingCard';
        }else{
          className = 'myTurn';
        }
      }else{
        className = 'notMyTurn';
      }
      return className;
    }
  }

  const [cacheLoading, setCacheLoading] =useState(true);

  useEffect(() => {
    const img = [
      '/images/cards/svg/2C.svg',
      '/images/cards/svg/2D.svg',
      '/images/cards/svg/2H.svg',
      '/images/cards/svg/2S.svg',
      '/images/cards/svg/3C.svg',
      '/images/cards/svg/3D.svg',
      '/images/cards/svg/3H.svg',
      '/images/cards/svg/3S.svg',
      '/images/cards/svg/4C.svg',
      '/images/cards/svg/4D.svg',
      '/images/cards/svg/4H.svg',
      '/images/cards/svg/4S.svg',
      '/images/cards/svg/5C.svg',
      '/images/cards/svg/5D.svg',
      '/images/cards/svg/5H.svg',
      '/images/cards/svg/5S.svg',
      '/images/cards/svg/6C.svg',
      '/images/cards/svg/6D.svg',
      '/images/cards/svg/6H.svg',
      '/images/cards/svg/6S.svg',
      '/images/cards/svg/7C.svg',
      '/images/cards/svg/7D.svg',
      '/images/cards/svg/7H.svg',
      '/images/cards/svg/7S.svg',
      '/images/cards/svg/8C.svg',
      '/images/cards/svg/8D.svg',
      '/images/cards/svg/8H.svg',
      '/images/cards/svg/8S.svg',
      '/images/cards/svg/9C.svg',
      '/images/cards/svg/9D.svg',
      '/images/cards/svg/9H.svg',
      '/images/cards/svg/9S.svg',
      '/images/cards/svg/10C.svg',
      '/images/cards/svg/10D.svg',
      '/images/cards/svg/10H.svg',
      '/images/cards/svg/10S.svg',
      '/images/cards/svg/AC.svg',
      '/images/cards/svg/AD.svg',
      '/images/cards/svg/AH.svg',
      '/images/cards/svg/AS.svg',
      '/images/cards/svg/JC.svg',
      '/images/cards/svg/JD.svg',
      '/images/cards/svg/JH.svg',
      '/images/cards/svg/JS.svg',
      '/images/cards/svg/JK.svg',
      '/images/cards/svg/JK2H.svg',
      '/images/cards/svg/JK3H.svg',
      '/images/cards/svg/JK4H.svg',
      '/images/cards/svg/JK5H.svg',
      '/images/cards/svg/JK6H.svg',
      '/images/cards/svg/JK7H.svg',
      '/images/cards/svg/JK8H.svg',
      '/images/cards/svg/JK9H.svg',
      '/images/cards/svg/JK10H.svg',
      '/images/cards/svg/JKAH.svg',
      '/images/cards/svg/JKJH.svg',
      '/images/cards/svg/JKKH.svg',
      '/images/cards/svg/JKQH.svg',
      '/images/cards/svg/JS.svg',
      '/images/cards/svg/KC.svg',
      '/images/cards/svg/KD.svg',
      '/images/cards/svg/KH.svg',
      '/images/cards/svg/KS.svg',
      '/images/cards/svg/QC.svg',
      '/images/cards/svg/QD.svg',
      '/images/cards/svg/QH.svg',
      '/images/cards/svg/QS.svg',
      '/images/cards/svg/back.svg',
    ];

    cacheImages(img);
  }, [])

  const cacheImages = async (srcArray) => {
    const promises = srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = `${cdnUrl}${src}?v=${imageVersion}`;
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    });
  
    await Promise.all(promises).then(() => {
      setCacheLoading(false);
    }).catch(error => {
      console.error("Failed to load images", error);
      setCacheLoading(false);
    });
  }

  if(loading || cacheLoading){
    return <Loader />
  }
  else{
  return (
    <WithAuthorizationGuard>
      <Head>
        {process.env.NEXT_ENABLE_GTAG == 'true' && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}></script>
            <script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                  page_path: window.location.pathname,
                });
              `,
                }}
            />
          </>
        )}
        {process.env.NEXT_ENABLE_HOTJAR == 'true' && (
        <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1879307,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
            }}
        />)}
      </Head>
      <Suspense fallback = {<Loader />}>
        <div
          className={styles.gamePage + " " + (showPyro ? styles.pyro : "") + " " + styles[activeBoardTheme.id] + " " + (isMobile ? styles.mobile : styles.desktop) + " " + (isMobile ? "mobile" : "desktop") + " " + (gameData?.isStealingMode ? styles.stealing : '')} ref={gamePageRef}
          style = {{
            'backgroundImage' : `${activeBoardTheme.id === 'no_theme' ? '' : `url(/images/gamepageBackground/${activeBoardTheme.id}.jpg)`}`
          }}
          >

          {/* Prevent screen lock */}
          {/* <Video /> */}
          {showSyncLoader && <SyncGameLoader />}          
          {!!gameData.chat && window.pusher && toggleChat && !modalState && (
            <PredefinedChat
              chatId={gameData.chat.id}
              predefinedTexts={gameData.chat.predefinedTexts}
              isMobile = {isMobile}
              closeChat={() => {
                setToggleChat(false);
                setToggleBackdrop(false);
                setDelayIsActive(false);
              }}
              delayIsActicve={delayIsActicve}
              closeDelay={() => {
                setDelayIsActive(false);
              }}
              setDelayIsActive = {setDelayIsActive}
              onSetPlayerMessage = {onSetPlayerMessage}
              socketId = {socketId}
              // pusher = {pusher}
            />
          )}
          <div className={styles.before}></div>
          <div className={styles.after}></div>
          {/* {showCountDown && <AddModal />} */}
          {rematchModal && (
            <RematchModal
              closeModal={() => {
                rematchGame(false)
              }}
              setRematchModal={setRematchModal}
              gameData={gameData}
              rematchGame={() => {rematchGame(true)}}
            />
          )}
          {modalState && !!gameData && (
            <GameModal
              modalType={modalFor}
              isMobile={isMobile}
              forceFinished = {gameData.forceFinished}
              playTwoData={{
                card: { ...gameData.actionCard },
                canPlayTwo: !!gameData.currentPlayer?.canPlayTwo,
                canSteal: !!gameData.currentPlayer?.canSteal,
                victims: [...gameData?.victims],
                onSetCardPlayAction: onSetCardPlayAction,
                socketId: socketId,
                gameId: router.query.gameId,
                lobbyId: router.query.lobbyId,
                actionCard: gameData.actionCard,
                handleOpenOfflineModalState: handleOpenOfflineModalState,
                buzzModalHandler: buzzModalHandler,
                onGameCommontCardEvent: onGameCommontCardEvent,
                isStealingMode: gameData.isStealingMode,
                gamePublicActionChannel: gamePublicActionChannel,
                stealerId: gameData.stealerId,
                onSetChosenVictim: onSetChosenVictim,
                currentPlayerId: gameData.currentPlayer.id
              }}
              exchangeData={{
                playerWithWhomToExchangeCards:
                  gameData.playerWithWhomToExchangeCards,
                gameId: router.query.gameId,
                lobbyId: router.query.lobbyId,
                socketId: socketId,
                currentPlayer: gameData.currentPlayer,
                actionCard: gameData.actionCard,
                onGameCommontCardEvent: onGameCommontCardEvent,
                totalTime: gameData.currentPlayer.timeLeft,
                showProgressBar: gameData.type === 'classic'
              }}
              jokerData={{
                cardAliases: [...gameData.cardAliases],
                gameId: router.query.gameId,
                lobbyId: router.query.lobbyId,
                socketId: socketId,
                currentPlayer: gameData.currentPlayer,
                actionCard: gameData.actionCard,
                onGameCommontCardEvent: onGameCommontCardEvent,
              }}
              stealData={{
                currentPlayer: gameData.currentPlayer,
                gameId: router.query.gameId,
                lobbyId: router.query.lobbyId,
                gamePublicActionChannel: gamePublicActionChannel,
                stealer: gameData.stealer,
                socketId: socketId,
                actionCard: gameData.actionCard,
                onGameCommontCardEvent: onGameCommontCardEvent,
              }}
              removeBackdrop={() => setToggleBackdrop(prev => !prev)}
            ></GameModal>
          )}
          {openBoardCardsModule && (
            <GameModal
              modalType={"boardCards"}
              boardCards={gameData.boardCards}
              closeModal={() => {
                handleBoardCardsModalStateChange(false);
                setToggleBackdrop(false)
              }}
            />
          )}
          {cardStyleSelector && showCardStyleSelectorModal && (
            <GameModal
              onCardStyleChange = {settingKey => dispatch(saveSettings(settingKey))}
              modalType={"cardStyleSelector"}
              closeModal={() => {
                dispatch(saveSettings({showCardStyleSelectorModal: false}))
                setCardStyleSelector(false);
              }}
            />
          )}
          {openReplaySevenModule && (
            <GameModal
              modalType={"alert"}
              isMyTurn={gameData.currentPlayer?.myTurn}
              closeModal={() => {
                setOpenReplaySevenModule(false);
                setToggleBackdrop(false)
              }}
              gameData={gameData}
            />
          )}

          <div className={`${styles.gamePage_ui_holder} ${styles[ui_holder_style()]}`}>
            {
              <GamepageHeader 
                toggleInfo= {() => {setIsInfoOpen(prevState => !prevState)}}
                isIos= {isIos}
                fullScreen= {fullScreen}
                adminHandler= {() => {backAsAdminHandler()}}
                notifRef= {notifRef}
                handleNotificationModalOpen={handleNotificationModalOpen}
                isNotifOpen={isNotifOpen}
                openNotif={() => setIsNotifOpen(prevState => !prevState)}
                menuRef={menuRef}
                handleHamburgerMenuOpen={handleHamburgerMenuOpen}
                isMobile={isMobile}
                isMenuOpen={isMenuOpen}
                handleClick = {handleLeaveClick}
              >                  
                {isMenuOpen && 
                  <GamePageHanburgerMenu
                    user = {user}
                    openSidebar = {onToggleSidebar}
                    onSetUserForProfileSidebar = {onSetUserForProfileSidebar}
                    router = {router}
                    handleClick = {handleLeaveClick}
                  />
                }
              </GamepageHeader>
            }
            
            <div className={styles.gamePage_ui_holder_body}>
              {/* {gameData.isGameDataLoaded && !!gamePublicActionChannel && !!socketId && ( */}
              {gameData.isGameDataLoaded && !!gamePublicActionChannel && (
                <Board
                  marbles={gameData.marbles}
                  holes={gameData.holes}
                  currentPlayer={gameData.currentPlayer}
                  boardCards={gameData.boardCards}
                  gameTable={gamePageRef}
                  currentPlayerId={gameData.currentPlayer.id}
                  gameId={router.query.gameId}
                  lobbyId={router.query.lobbyId}
                  roundState={gameData.roundState}
                  gamePublicActionChannel={gamePublicActionChannel}
                  players={gameData.players}
                  showCountDown={showCountDown}
                  dropCardsHandler={dropCardsHandler}
                  isStealingMode={gameData.isStealingMode}
                  onGameCommontCardEvent={onGameCommontCardEvent}
                  socketId={socketId}
                  actionCard={gameData.actionCard}
                  cardPlayAction={gameData.cardPlayAction}
                  myTurn={gameData.currentPlayer.myTurn}
                  id={gameData.currentPlayer.id}
                  dropped={gameData.currentPlayer.dropped}
                  playingCard={gameData.currentPlayer.playingCard}
                  playingMarble={gameData.currentPlayer.playingMarble}
                  boardRotate={localStorage.boardToggle}
                  onSetChosenVictim={onSetChosenVictim}
                  openBoardCardsModule={() => {
                    handleBoardCardsModalStateChange(true);
                    setToggleBackdrop(true)
                  }}
                  onSetPlayerMessage={onSetPlayerMessage}
                  stealerId={gameData.stealer.id}
                  colors={gameData.colors}
                  modalFor={modalFor}
                  roundStarterObj={gameData.roundStarterObj}
                  canGoToOpponent={canGoToOpponent}
                  handleCanGoToOpponent={handleCanGoToOpponent}
                  isJoystick={isJoystick}
                  onToggleSidebar = {onToggleSidebar}
                  onSetUserForProfileSidebar = {onSetUserForProfileSidebar}
                  stealingCard = {gameData.currentPlayer.stealingCard}
                  footerElement={footerElement}
                  setToggleChat = {setToggleChat}
                  toggleBackdrop={toggleBackdrop}
                  choosingVictim = {gameData.choosingVictim}
                  timesLeft = {gameData.timesLeft}
                  type = {gameData.type}
                  victims = {[...gameData?.victims]}
                  showBackdrop={() => setToggleBackdrop(true)}
                  forceFinished = {gameData.forceFinished}
                  stealingInProgress = {stealingInProgress}
                  setStealingInProgress = {setStealingInProgress}
                  showSevenSteps={gameData.showSevenSteps}
                />
              )}
            </div>
            <div ref={footerElement} className={`${styles.gamePage_ui_holder_footer} ${gameData?.isStealingMode ? styles.stealing : ''}`}>
              {showCountDown && (
                <div className={styles.dropping_label}>
                  <span className={styles.dropping_label_text}>
                  {t('common:dropping')}
                  </span>
                  <img className={styles.icon_clock} src="/images/common/Clock.svg"/>
                  <Countdown time = {10} callBackFunc = {() => dropCardsHandler(gameData.currentPlayer.id)} type="html"/>
                </div>
              )}
              <div className={styles.gamePage_ui_holder_footer_buttons}>
                {/* <GradientButtons>
                    <Assistace onClick={fullScreen}/>
                </GradientButtons> */}
                <GradientButtons click={fullScreen}>
                  {
                    !toggleFullscreen ?
                      <img src="/images/gamePageButtons/fullscreen.svg" />
                    :
                      <img src="/images/gamePageButtons/exit_fullscreen.svg" />
                  }
                </GradientButtons>
                {/* <div className={styles.rotation_button}>
                  <GradientButtons onClick={onSetBoardToggle}>
                    <span>{localStorage.boardToggle === "false" ? '3D' : '2D'}</span>
                  </GradientButtons>
                </div> */}
                {/* <div className={styles.history_buttons}>
                  <RegularButtons><img src="/images/svg/undo.svg" /></RegularButtons>
                  <RegularButtons disabled><img src="/images/svg/redo.svg" /></RegularButtons>
                  <RegularButtons disabled><img src="/images/svg/play.svg" /></RegularButtons>
                </div> */}
                <div className={styles.emoji_button}>
                <GradientButtons className="emoji_button" click={() => {setToggleChat((prevState) => !prevState)}}>
                  <img src="/images/gamePageButtons/emoji.svg" />
                </GradientButtons>
                </div>
              </div>
              <div className={`${styles.card_container} ${(showCountDown && styles.droppingCards)}`}>                
                {gameData.isGameDataLoaded && gameData.roundState !== "exchange" && isMobile && (
                  gameData.currentPlayer.cards.map((card, cardIndex, arr) => {
                    return <Card
                        cardIndex={cardIndex}
                        key={cardIndex}
                        playerIndex = {0}
                        card = {card}
                        isVictim = {gameData.currentPlayer.victim}
                        cardMargin={0}
                        width={88}
                        height={132}
                        gamePublicActionChannel = {gamePublicActionChannel}
                        gameId={router.query.gameId}
                        lobbyId={router.query.lobbyId}
                        roundState={gameData.roundState}
                        showCountDown={showCountDown}
                        isStealingMode={gameData.isStealingMode}
                        onGameCommontCardEvent={onGameCommontCardEvent}
                        socketId={socketId}
                        actionCard={gameData.actionCard}
                        cardPlayAction={gameData.cardPlayAction}
                        myTurn={gameData.currentPlayer.myTurn}
                        id={gameData.currentPlayer.id}
                        dropped={gameData.currentPlayer.dropped}
                        playingCard={gameData.currentPlayer.playingCard}
                        stealingCard = {gameData.currentPlayer.stealingCard}
                        playingMarble={gameData.currentPlayer.playingMarble}
                        stealerId = {gameData.stealer.stealerId}
                        chosenAsVictim = {gameData.players[0].chosenAsVictim}
                        responsive={true}
                        forceFinished = {gameData.forceFinished}
                        cardLength={arr.length}
                        device="mobile"
                        stealingInProgress = {stealingInProgress}
                        setStealingInProgress = {setStealingInProgress}
      
                    />
                  })
                )}
                {gameData.isGameDataLoaded && (
                  gameData.currentPlayer.cards.length === 0 && isMobile && !gameData.currentPlayer.playingMarble && (
                    <div className={styles.card_container_no_card}>
                      <NoCardsModal text = {t('common:no_cards')}/>
                    </div>
                  )
                )}
              </div>
              {
                <OtherPlayersIndicator 
                  players={gameData?.players}
                  roundState={gameData?.roundState}
                  myTurn={gameData?.currentPlayer?.myTurn}
                  isMobile={isMobile}
                />
              }
              
            </div>

            <div className={styles.gamePage_ui_holder_bottom_left}>
              <div className={styles.text_container}>
                  <div onClick = {() => {
                        onToggleSidebar({
                          sidebarType: 'rules',
                          isOpen: true,
                          hasTitle: true,
                          showSidebarCloseButton: true
                        })
                      }}
                    >
                    <img src="/images/svg/game_rules.svg" />
                    <span>{t('common:sidebar_titles.game_rules')}</span>
                  </div>
                  {/* <div>
                    <img src="/images/svg/report_bug.svg" />
                    <span>Report a Bug</span>
                  </div> */}
              </div>
            </div>

            {gameData.isGameDataLoaded && !!gamePublicActionChannel && (
              <>
                {!!gameData.roundStarterObj && (
                  <>
                    {isInfoOpen && (
                      <div className={styles.game_stats_holder}>
                        <GameStats
                          recentActions={gameData.recentActions}
                          roundNumber={gameData.roundStarterObj.round}
                          thisRoundStarter={{
                            ...gameData.roundStarterObj.roundStarts,
                            cardCount: gameData.roundStarterObj.roundCardsCount,
                          }}
                          nextRoundStarter={{
                            ...gameData.roundStarterObj.nextRoundStarts,
                            cardCount: gameData.roundStarterObj.nextRoundCardsCount,
                          }}
                          players = {gameData.players}
                          gameMode = {gameData.mode}
                          versus = {gameData.vs}
                          friendsList = {friendsList}
                          rules = {gameData.rules.active}
                          onFriendRequest = {onFriendRequest}
                          showGameInfo = {setIsInfoOpen}
                          playersScoreboard = {gameData.playersScoreboard}
                          dealer = {gameData.roundStarterObj.dealer}
                          onUpdatePlayerFriendshipStatus = {onUpdatePlayerFriendshipStatus}
                          gameName = {gameData.type === 'classic' ? t('common:online_game') : gameData.name}
                          cardStyle = {cardStyle}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            { isRefreshPopupShow && <ConnectionErrorPopup onSendStatToBackend = { onSendStatToBackend }/> }
            { toggleBackdrop && <Backdrop /> }
          </div>
        </div>


      {feedbackState.show &&
      <FeedbackModal 
        handleChange={handleCommentChange} 
        toggleIssueType={toggleIssueType} 
        handleSubmit={handleFeedbackSubmit} 
        handleClose={handleFeedbackClose}
        handleLeave={async () => {
          if (router.query.lobbyId && router.query.gameId) {
            await api.post(`/lobby/${router.query.lobbyId}/game/${router.query.gameId}/leave`)
            window.location.href = `/${router.locale}`
          }
        }}
        {...feedbackState}/>
      }
      {showOfflineModal && offlinePlayers && playerOfflineModalTitle &&
        <OfflineModal
          players={offlinePlayers}
          offlineStatus = {playerOfflineModalTitle}
          newGameSearchHandler = {() => {
            const matchMakingDetails = {
              mode: gameData.mode,
              type: gameData.type,
              players: gameData.players.length,
              rules: gameData.rules.active.map(rule => rule.id),
              vs: gameData.vs,
              region: 'global',
              name: t('common:online_game'),
            }
            dispatch(resetPublicLobby())
            dispatch(resetMm())
            localStorage.setItem('matchMakingDetails', JSON.stringify(matchMakingDetails))
            // await onSearchNewGame(matchMakingDetails)
            window.location.href = `/${router.locale}`
          }}
          currentPlayerTeamId = {gameData.currentPlayer.team.id}
          currentPlayerId = {gameData.currentPlayer.id}
          gameStarted = {gameData.roundStarterObj.round != '1' || gameData.roundState !== 'exchange'}
        />
      }
      { showOfflineModal &&
        <Backdrop 
          closeFunc={() => {}}
        />
      }
      </Suspense>
    </WithAuthorizationGuard>
  );
  }
};

export const GamePageHanburgerMenu = ({user, openSidebar, onSetUserForProfileSidebar, router, handleClick}) => {
  const {t} = useTranslation()
  return (
    <div className = {styles.hamburger_container}>
      <ul className = {styles.hamburger_menu_ul}>
        <li className = {styles.hamburger_menu_li} onClick = {() => {
          onSetUserForProfileSidebar({
            ...user,
            isLoggedInUser: true
          })
          openSidebar({
            isOpen: true,
            sidebarType: 'profile',
            hasTitle: true,
            showSidebarCloseButton: true
          })
        }}>
          <div className = {styles.hamburger_menu_image}>
            <div className = {styles.image_container}>
              <Avatar 
                hasFlag = {false}
                avatarUrls = {user.avatar}
                size = "small"
              />              
            </div>
          </div>
          <div className = {styles.hamburger_menu_text}>
            <span className = {styles.text}>{t('common:sidebar_titles.my_profile')}</span>
          </div>
        </li>
        <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
              sidebarType: 'rules',
              isOpen: true,
              hasTitle: true,
              showSidebarCloseButton: true
            })}}
          >
          <div className = {styles.hamburger_menu_image}>
            <div className = {styles.image_container}>
              <GameRulesSvg />
            </div>
          </div>
          <div className = {styles.hamburger_menu_text}>
            <span className = {styles.text}>{t('common:sidebar_titles.game_rules')}</span>
          </div>
        </li>
        <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
              isOpen: true,
              sidebarType: 'settings',
              hasTitle: true,
              showSidebarCloseButton: true
            })}}>
          <div className = {styles.hamburger_menu_image}>
            <div className = {styles.image_container}>
              <SettingsSvg />
            </div>
          </div>
          <div className = {styles.hamburger_menu_text}>
            <span className = {styles.text}>{t('common:sidebar_titles.settings')}</span>
          </div>
        </li>
      </ul>
      <div className = {styles.leave_button_container}>
        <button className = {styles.leave_button} onClick = {handleClick}>
          {t('common:buttons.leave')}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    gameData: state.gameData,
    pusher: state.pusher.pusher,
    socketId: state.pusher.socketId,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    friendsList: state.friends.friendsList,
    activeBoardTheme: state.settings.activeBoardTheme,
    isSoundOn: state.settings.isSoundEffectsOn
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetGameData: (gameId) => dispatch(gameDataAction.getGameData(gameId)),
    onGameCommontCardEvent: (requestData) =>
      dispatch(gameDataAction.gameCommontCardEvent(requestData)),
    onCardExchangeHandler: (card) =>
      dispatch(gameDataAction.hanldeCardExchange(card)),
    onHandleCardSteal: (exchangeData) =>
      dispatch(gameDataAction.handleCardSteal(exchangeData)),
    onHandleCardPlay: (payload) =>
      dispatch(gameDataAction.handleCardPlay(payload)),
    onChangePlayerTurn: (changeTurnObj) =>
      dispatch(gameDataAction.changeTurn(changeTurnObj)),
    onSetPlayersForStealing: (payload) =>
      dispatch(gameDataAction.setPlayersForStealing(payload)),
    onSetNewRoundData: (cardData) =>
      dispatch(gameDataAction.setNewRoundData(cardData)),
    onSetCardPlayAction: (cardPlayAction) =>
      dispatch(gameDataAction.setCardPlayAction(cardPlayAction)),
    onDropCards: (playerId) => dispatch(gameDataAction.dropCards(playerId)),
    onSetPLayersReadyForExchange: (playerId) =>
      dispatch(gameDataAction.setPLayersReadyForExchange(playerId)),
    setCanPlayStatusToCards: (data) =>
      dispatch(gameDataAction.setCanPlayStatusToCards(data)),
    // onSetSocketId: (socketId) => dispatch(setSocketId(socketId)),
    onMarbleMove: (data) => dispatch(gameDataAction.marbleMove(data)),
    // onReplaySeven: (data) => dispatch(gameDataAction.replaySeven(data)),
    onMarbleKill: (data) => dispatch(gameDataAction.marbleKill(data)),
    onMarbleSwap: (data) => dispatch(gameDataAction.marbleSwap(data)),
    onGameFinish: (data) => dispatch(gameDataAction.onGameFinish(data)),
    setRematchApproval: (data) =>
      dispatch(gameDataAction.setRematchApproval(data)),
    onSetPlayerMessage: (data) =>
      dispatch(gameDataAction.stePLayerMessage(data)),
    onToggleSidebar: (sidebarState) => dispatch(toggleSidebar(sidebarState)),
    onSetRecentAction: (payload) =>
      dispatch(gameDataAction.setRecentAction(payload)),
    onSetDroppedCards: (payload) =>
      dispatch(gameDataAction.setDroppedCards(payload)),
    onSetBoardToggle: () => dispatch(gameDataAction.setBoardToggle()),
    onSetChosenVictim: (playerId) =>
      dispatch(gameDataAction.setChosenVictim(playerId)),
    // onToggleBackdropState: (state) => dispatch(toggleBackdropState(state)),
    onUpdateScoreboard: (payload) => dispatch(gameDataAction.updateScoreboard(payload)),
    onFriendRequest: (payload) => dispatch(friendRequest(payload)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onResetGameData: () => dispatch(gameDataAction.resetGameData()),
    onUpdatePlayerFriendshipStatus: (payload) => dispatch(updatePlayerFriendshipStatus(payload)),
    // onSearchNewGame: (payload) => dispatch(startMatchMaking(payload))
    onSendStatToBackend: (payload) => dispatch(sendStatsToBackend(payload)),
  };
};
const __Page_Next_Translate__ = connect(mapStateToProps, mapDispatchToProps)(Game);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  