import Header from "../components/Header/Header";
import Head from 'next/head';
import Sidebar from '../components/Sidebar/Sidebar.js';
import { checkAuth, checkAuthFail } from '../store/auth/authActions';
import { connect } from 'react-redux';
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { instantiatePusher } from '../store/pusher/pusherActions';
import { useRouter } from "next/router";
import { toggleSidebar, setGlobalModalState } from "../store/modals/modalsActions";
import { getSettings } from "../store/settings/settingsActions";
import Swal from 'sweetalert2' 
import Loader from "../components/Loader/Loader";
import { getCountriesList } from "../store/static/staticInfoActions";
import { getLobbySuccess, playerJoin, setJoinedPlayer } from "../store/lobby/publicLobby/lobbyActions";
import { matchMakingSuccess , cancelMatchMaking, resetMm, startMatchMaking, toggleDisableAborting, startquickPlay } from "../store/matchMaking/matchMakingActions";
// import { GameFoundModalBody, SearchingGameModalBody } from "../components/MatchMakingModals/MatchMakingModals";
import { resetSetup, getGameSetupData, updateLiveSearchingPlayerNumber } from "../store/gameSetup/gameSetupActions";
import { getNotifications, setNewNotification, markNotificationAsRead } from "../store/notifications/notificationActions";
import { getFriendList, sendAcceptSuccess, updateFriendOnlineStatus, sendDeleteRequestSuccess } from "../store/friends/friendActions";
import { getAvatars } from "../store/avatars/avatarsActions";
import { toggleBackdropState } from "../store/backdropState/backdropStateActions";
import Backdrop from "../components/Backdrop/Backdrop";
import AvatarsSlider from "../components/AvatarsSlider/AvatarsSlider";
import getQueryParams, { getRouterQuery } from "../helpers/GetQueryParams";
import { CSSTransition } from "react-transition-group";
import { setSocketId } from "../store/pusher/pusherActions";
import * as styles from '../styles/scss/components/Layout/Layout.module.scss';
import ResponsivePopUp from "../components/ResponsivePopUp/ResponsivePopUp";
import { toggleResponsivePopUp } from "../store/modals/modalsActions";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import CommonSearchingModal from "../components/GameSearchingStateModals/Index";
import ConnectionLostModal from "../components/ConnectionLostModal";
import OnboardingStepper from "../components/Onboarding/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { setOfflineModalState } from "../store/modals/modalsActions";
// import { USERSNAP_API_KEY } from '../config/usersnap.config'
import { Howl } from "howler";
import { setColaborators } from "../store/static/staticInfoActions";
import Error from "../pages/_error";
import notificationSound from '../sounds/notification.mp3';
import SpecialThanks from "../components/SpecialThanks";
import { getVersion } from "../store/static/staticInfoActions";
import { Tooltip } from "../components/Onboarding/OnboardingTooltips";
import { deviceType } from "../helpers/functions";
import Pusher from 'pusher-js';
import config from '../config/pusher.config'
import useTranslation from "next-translate/useTranslation";
import { setMaintenanceMode } from "../store/settings/settingsActions";
import api from '../axios/api';
import {BASE_URL} from "../config/api.config";
import { toggleModalSize } from "../store/modals/modalsActions";
import { resetOngoingGame } from "../store/auth/authActions";
import FeedbackSvg from '../public/images/report-bug.svg'
import { getMobileOS } from "../helpers/functions";
const Layout = (props) => {
  const {
    children,
    onCheckRequest, 
    onPusherInstanceCreation, 
    onToggleSidebar,
    sidebarState,
    sidebarType,
    authMessage,
    loading,
    sidebarHasBackArrow,
    callBackSidebarFunction,
    hasTitle,
    onGetCountriesList,
    globalModalState,
    pusher,
    isAuthenticated,
    user,
    lobby,
    matchMaking,
    onMatchMakingSuccess,
    onPlayerJoin,
    onSetJoinedPlayer,
    onSetGlobalModalState,
    onGetLobbySuccess,
    onMatchMakingCancel,
    OnResetSetup,
    onGetNotifications,
    onSetNewNotification,
    onGetFriendListRequest,
    onGetAvatarList,
    landingType,
    isBackdropOpen,
    onToggleBackdropState,
    onGetGameSetuppData,
    onSetSocketId,
    onSendAcceptSuccess,
    isResponsivePopUpOpen,
    onToggleResponsivePopUp,
    responsivePopUpType,
    isConfirmModalOpen,
    confirmModalData,
    onGetSettings,
    onResetMM,
    onMarkNotificationAsRead,
    socketId,
    onUpdateFriendOnlineStatus,
    sidebarCustomTitle,
    offlineModalState,
    showSidebarCloseButton,
    errorsData,
    onSendDeleteRequestSuccess,
    title,
    description
  } = props

  const router = useRouter();

  if (errorsData.errorOccured) {
    return <Error statusCode = {errorsData.statusCode} />
  }
  const [needsRotation, setNeedsRotation] = useState(false);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [gameCouldNotStart, setGameCouldNotStart] = useState(false)
  const [lobbyChannel, setLobbyChannel] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [playActionSound, setPlayActionSound] = useState(null)
  const [isSpecialThanksOpen, setIsSpecialThanksOpen] = useState(false)
  const isSoundOn = useSelector(state => state.settings.isSoundEffectsOn)
  const appVersionUpdate = useSelector(state => state.static.appVersionUpdate)
  const version = useSelector(state => state.static.version)  
  const unreadNotificationCount = useSelector(state => state.notifications.unreadNotificationCount)
  const [isMObileView, setIsMObileView] = useState(false)
  const [isInFocus, setIsInFocus] = useState(false)
  const [ongoingGame, setOngoingGame] = useState(null)
  const lang = useSelector(state => state.languages.currentLang);
  const [mobileOs, setMobileOs] = useState(getMobileOS())
  const [lobbyInformation, setLobbyInformation] = useState(null)
  const [showSearchingModal, setShowSearchingModal] = useState(false)
  const [dispatchStartGameAction, setDispatchStartGameAction] = useState(null)
  const aborting = useSelector(state => state.matchMaking.aborting)
  const { pathname, asPath, query, locale } = router
  const {t} = useTranslation()
  useEffect(() => {
    if (playActionSound) {
      try {
        const sound = new Howl({
          src: playActionSound,
          html5: true,
        });
        if (isSoundOn) {
          sound.play();
        } else{
          sound.mute(false)
        }
        setPlayActionSound(null)
        } finally {}
    }
  }, [playActionSound, isSoundOn])

  useEffect(() => {
    if (showSearchingModal && lobbyInformation && notificationSound) {
      if (dispatchStartGameAction) {
        setModalType(lobbyInformation.lobby.type)
        onSetGlobalModalState(false);
        onGetLobbySuccess(lobbyInformation.lobby);
        OnResetSetup();
        onSetGlobalModalState(true);
        onToggleBackdropState(false)
        onSetNewNotification(lobbyInformation)
        onMarkNotificationAsRead({
          notificationId: lobbyInformation.id,
          all: false
        })
        setPlayActionSound(notificationSound)
        const newTimeout = setTimeout(() => {
          setShowSearchingModal(true)
          onSetGlobalModalState(false)
          onResetMM()
          clearTimeout(newTimeout)
        }, 1000)
      } else {
        const matchMakingDetails = {
          mode: lobbyInformation.lobby.mode,
          type: lobbyInformation.lobby.type,
          players: lobbyInformation.lobby.members.length,
          rules: lobbyInformation.lobby.rules.map(rule => rule.id),
          vs: lobbyInformation.lobby.vs,
          region: 'global',
          name: t('common:online_game'),
        }
        const isQuickSearchSessionStorage = sessionStorage.getItem('isQuickSearch')

        if(isQuickSearchSessionStorage) {
          sessionStorage.removeItem('isQuickSearch')
          dispatch(startquickPlay())
          dispatch(toggleDisableAborting(false))
          return
        } else {
          dispatch(startMatchMaking(matchMakingDetails))
          dispatch(toggleDisableAborting(false))
          return
        }

      }
    }
  }, [lobbyInformation, showSearchingModal, notificationSound, dispatchStartGameAction])

  const showOnboardingElement = useSelector(state => state.onBoarding.showOnboardingElement)
  const modalIsMinimized = useSelector(state => state.modals.isSearchModalMinimized)
  const pageRef = useRef()
  const dispatch = useDispatch()
  const handleOpenOfflineModalState = (state) => {
    dispatch(setOfflineModalState(state))
  }

  useEffect(() => {
    if(isAuthenticated && lang != locale) {
      router.replace({ pathname, query }, asPath, { locale: lang })
    }
  }, [lang, isAuthenticated, locale, pathname])

  useEffect(() => {
    if (showOnboardingElement && router.pathname !== '/') {
      router.push('/')
    }
  }, [showOnboardingElement])

  const handleDocumentScroll = (e) => {
    if (e.target.scrollTop > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    if (pageRef.current) {
      const page = pageRef.current
      
      page.addEventListener('scroll', handleDocumentScroll)

      return () => {page.removeEventListener('scroll', handleDocumentScroll)}
    }
  }, [pageRef])

  useEffect(() => {
    if (authMessage) {
      Swal.fire({
        icon: authMessage.success ? 'success' : 'error',
        text: t(`errors:authorization.${authMessage.message}`),
        showCloseButton: true
      })
    }
  }, [authMessage])

  useLayoutEffect(() => {
    if (! localStorage.getItem('access_token')) {
      dispatch(checkAuthFail())
    }
  }, [])

  useEffect(() => {
    onGetAvatarList();
    const ISSERVER = typeof window === "undefined";
    if (!ISSERVER) {
      const access_token = localStorage.getItem('access_token')
      if (access_token) {
        onCheckRequest()
      }
      dispatch(getVersion())
    }

    const {email, token} = getQueryParams(router.asPath)
    if (email && token) {
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'resetPassword',
        hasTitle: true,
        showSidebarCloseButton: true
      })
    }

    const handleRouteChange = (url, { shallow }) => {
      if (!modalIsMinimized && globalModalState) {
        dispatch(toggleModalSize(true))
      }
      if (url.indexOf('?rule=') >= 0) {
        return
      } else {
        //this code closes every sidebar, if there is referral code in url, signup sidebar opens automatically and we don't need to close it
        onToggleBackdropState(false)
        const qs = getRouterQuery(router);
        if ((!qs.referralCode && !qs.rc) && !qs.email) {
          onToggleSidebar({
            isOpen: false,
            title: ''
          })
        }
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      setIsSpecialThanksOpen(false)
      dispatch(setColaborators(null))
    }
  }, [])

  const changeHeight = (e) => {
    if (mobileOs === 'ios') {
      const isLandscape = window.matchMedia("(orientation: landscape)").matches
      if (isLandscape) {
        pageRef.current.style.height = window.innerHeight + 'px'
      } else {
        pageRef.current.style.height = screen.availHeight + 'px'
      }
    } else {
      pageRef.current.style.height = window.innerHeight + 'px'
    }
  }

  useEffect(() => {
    if (isMObileView && pageRef && mobileOs) {
      pageRef.current.style.height = window.innerHeight + 'px'
      window.addEventListener('resize', changeHeight)
      return (() => {window.removeEventListener('resize', changeHeight)})
    }
   }, [isMObileView, pageRef, mobileOs])


  useEffect(() => {
    window.pusher?.connection.bind('state_change', states => {
      if ((states.current === 'failed' || states.current === 'unavailable') && !localStorage.getItem('socketId')) {
        handleOpenOfflineModalState(true)
        return
      }else if (states.current === 'failed' || states.current === 'unavailable') {
        setSocketId(null)
        localStorage.removeItem('socketId')
        handleOpenOfflineModalState(true)
      }
    });
  }, [window.pusher])

  useEffect(() => {
    if (socketId) {
      localStorage.setItem('socketId', socketId)
    }
  }, [socketId])


  useEffect(() => {
    if (!!user & !notificationChannel && !!window.pusher && isAuthenticated) {
      const channel = window.pusher.subscribe(`private-App.Models.User.${user.id}`);
      setNotificationChannel(channel)
    }

  }, [user, window.pusher, notificationChannel, isAuthenticated])

  useEffect(() => {
      window.pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, config)
      window.pusher.connection.bind("connected", () => {
        onSetSocketId(window.pusher.connection.socket_id);
      });
  }, [])

  useEffect(() => {
    if (!!user && !!window.pusher) {
      const readyForNewSearch = JSON.parse(localStorage.getItem('matchMakingDetails'))
      const isQuickSearchSessionStorage = sessionStorage.getItem('isQuickSearch')

      if (readyForNewSearch) {
        if(isQuickSearchSessionStorage) {
          dispatch(startquickPlay())
          return
        } else {
          localStorage.removeItem('matchMakingDetails')
          dispatch(startMatchMaking(readyForNewSearch))
          return
        }
      }
      if (user.ongoingGame) {
        setOngoingGame(user.ongoingGame)
        setModalTitle('Classic')
        setModalType('classic')
        onSetGlobalModalState(true)
        return
      }
      if (user.inMM) {
        setModalTitle(user.ongoingSearch.gameType.label)
        setModalType(user.ongoingSearch.gameType.value)
        if (!!user.gameFound) {
          onGetLobbySuccess({...user.gameFound, joined: user.gameAccepted})
          const lobbyChannel = window.pusher.subscribe(`private-Lobby.${user.gameFound.id}`);
          setLobbyChannel(lobbyChannel);
        } else {
          onMatchMakingSuccess({
            mmId: user.mmId,
            ongoingSearch: {...user.ongoingSearch}
          })
        }
        onSetGlobalModalState(true)
      } 
    }
  }, [window.pusher, user])

  useEffect(() => {
    if (isAuthenticated){
      onPusherInstanceCreation();
      let perPage = 10;
      const isTallerThanMobile = window.matchMedia("(min-height: 992px)").matches
      isTallerThanMobile ? perPage = 20 : perPage = 10
      onGetNotifications(0, perPage)
      onGetCountriesList();
      onGetFriendListRequest()
      onGetSettings();
      onGetGameSetuppData();
    }
    if (!isAuthenticated) {
      onSetGlobalModalState(false)
      setNotificationChannel(null)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!!lobbyChannel && !lobbyChannel.subscribed) {
      
      lobbyChannel.bind('player.joined', data => {
        const userId = data.user.id
        onSetJoinedPlayer(userId, user.id)
      })

      lobbyChannel.bind('start.game', startGameData => {
        if (aborting) {
          return
        }
        
        window.location.href = `/${router.locale}/lobby/${startGameData.lobbyId}/game/${startGameData.gameId}`
        setDispatchStartGameAction(startGameData)
      })
    }
  }, [lobbyChannel, aborting])

  useEffect(() => {
    // debugger
    if (matchMaking.searching) {
      setModalTitle(matchMaking.selectedGameData.gameType.label)
      setModalType(matchMaking.selectedGameData.gameType.value)
      onSetGlobalModalState(true)
      onToggleBackdropState(false)
    }
  }, [matchMaking.searching])

  useEffect(() => {
    if (!!notificationChannel && !notificationChannel.subscribed) {
      notificationChannel.unbind()
      notificationChannel.bind('pusher:subscription_error', (err) => {
          console.log(err)
          return
        }
      )
      notificationChannel.bind('silent.action', data => {
        if (data.action === 'unsetUserFromFriendlist') {
          onSendDeleteRequestSuccess(data.friendship.id)
        } else if (data.action === 'updatePlayerSearchCount') {
          const searchData = {...data.activeSearches}
          const playersSearching = [searchData.solo_2, searchData.solo_3, searchData.solo_4, searchData.team, searchData.solo_any]
          dispatch(updateLiveSearchingPlayerNumber(playersSearching))
        } else if (data.action === 'api:ping') {
        // TODO: Send POST request to api/track with payload: {isa: X-Socket-ID, isa2: 'pusher:pong'}
          if (data.socketId !== socketId) return;
          const body = {
            isa: socketId,
            isa2: 'api:pong',
            isa3: user?.id
          }
          try {
            const blob = new Blob([JSON.stringify(body)]);
            navigator.sendBeacon(BASE_URL + '/track', blob);  
          } finally {}
        } else if (data.action === 'toggleMaintenance') {
          dispatch(setMaintenanceMode(data.maintenanceMode))
        } else if (data.action === 'disableAbortButtonMM') {          
          dispatch(toggleDisableAborting(true))
        }
      })
      notificationChannel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
        if (data.type === 'MatchReady') {

          const lobbyChannel = window.pusher.subscribe(`private-Lobby.${data.lobby.id}`);
          setLobbyChannel(lobbyChannel);
          const timeout = setTimeout(() => {
            setLobbyInformation(data)
            setShowSearchingModal(true)
            clearTimeout(timeout)
          }, 15000)

        } else if (data.type === 'FriendshipRequested') {
          onSetNewNotification(data)
          setPlayActionSound(notificationSound)

        } else if (data.type === 'FriendshipAccepted') {
          // debugger;
          onSetNewNotification(data)
          onSendAcceptSuccess(data.friendship)
          setPlayActionSound(notificationSound)
        } else if (data.type === 'GameRequested') {
          onSetNewNotification(data)
          setPlayActionSound(notificationSound)

        } else if (data.type === 'UserCameOnline') {
          // debugger;
          onUpdateFriendOnlineStatus({friendId: data.user.id, onlineStatus : true})
        } else if (data.type === 'UserWentOffline') {
          // debugger;
          onUpdateFriendOnlineStatus({friendId: data.user.id, onlineStatus : false})
        }
      })
    }
  }, [notificationChannel, socketId])

  useEffect(() => {
    if (!socketId || !user) return;
    const interval = setInterval(() => {
      const body = {
        isa: socketId,
        isa2: 'api:pong',
        isa3: user.id
      }
      try {
        const blob = new Blob([JSON.stringify(body)]);
        navigator.sendBeacon(BASE_URL + '/track', blob);  
      } finally {}
    }, 30000);
    return () => clearInterval(interval)
  }, [socketId, user]);

  const handleMatchMakingCancel = async () => {
    if (matchMaking.searching) {
      localStorage.removeItem('matchMakingDetails')
      sessionStorage.removeItem('isQuickSearch')
      onMatchMakingCancel(matchMaking.selectedGameData.mmId);
      onSetGlobalModalState(false)
      dispatch(toggleModalSize(false))
      OnResetSetup()
      return
    }
  }

  const getPagePath = () => {
    const hasFixedHeight = router.route.indexOf('lobby') || router.route.indexOf('leaderboard') >= 0
    return hasFixedHeight
  }


  useEffect(() => {
    onToggleBackdropState(sidebarState || isResponsivePopUpOpen || isConfirmModalOpen)
  }, [sidebarState, isResponsivePopUpOpen, isConfirmModalOpen])


  useEffect(() => {
    if (sidebarState) {
      pageRef.current.style.overflow = 'hidden'
    } else {
      pageRef.current.style.overflowX = 'hidden'
      pageRef.current.style.overflowY = 'auto'
    }
  }, [sidebarState, pageRef])

  useEffect(() => {
    document.addEventListener('visibilitychange', sendRequest)
    const device = deviceType()
    setIsMObileView(device === 'mobile' || device === 'tablet')

  }, [])

  useEffect(() => {
    if (isInFocus) {
      if (window.pusher.connection.state === 'connected') {
        window.pusher.allChannels().forEach(channel => {
          if (!channel.subscribed || channel.subscriptionPending) {
            handleOpenOfflineModalState(true)
            return
          }
        })
        handleOpenOfflineModalState(false)
      } else {
        handleOpenOfflineModalState(true)
        // setNotificationChannel(null)
        onPusherInstanceCreation()
      }
    }
  }, [isInFocus, window.pusher])

  const sendRequest = (e) => {
    if (document.visibilityState === 'visible') {
      setIsInFocus(true)
    } else if (document.visibilityState === 'hidden') {
      setIsInFocus(false)
    }
    const body = {
      isa: localStorage.getItem('socketId'),
      isa2: localStorage.getItem('userId')
    }
    const blob = new Blob([JSON.stringify(body)]);
  }

  const isHowToPLay = () => {
    return router.pathname === '/how-to-play'
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="description" content={description}/>
        <title>{unreadNotificationCount > 0 ? `(${unreadNotificationCount}) ` : ''}{title}</title>
      </Head>
      <div 
        className={`${styles.content} ${styles.dark}  ${!isAuthenticated && router.pathname == '/' && styles.unAuthorizedBackground} ${!isAuthenticated && `${styles.landing} ${styles[landingType]}`} ${router.pathname !== '/' && styles.noBg}`} ref = {pageRef}
        >
          <>
            {router.pathname !== '/lobby/[lobbyId]/game/[gameId]' && <Header scrolled = {scrolled} openSidebar = {onToggleSidebar} />}
            {(router.pathname === '/lobby/[lobbyId]/game/[gameId]') ? children : 
              <section className = {`${styles.page_body} ${getPagePath() && styles.fixedHeight} ${ router.pathname === '/' && styles.landing} ${ router.pathname === '/lobby/[lobbyId]' && styles.lobbyPage} ${router.pathname === '/leaderboard' && styles.leaderboardPage} ${isHowToPLay() && styles.how_to_play}`}>
                {children}
              </section>
            }

            <CSSTransition
              in = {sidebarState}
              timeout = {500}
              unmountOnExit
              classNames = 'sidebar'
              >
              <Sidebar
                sidebarType = {sidebarType}
                callBackFunc = {() => {
                  onToggleSidebar({
                    isOpen: false,
                    sidebarType: '',
                    hasTitle: true
                  })
                }}
                sidebarHasBackArrow = {sidebarHasBackArrow}
                callBackSidebarFunction = {callBackSidebarFunction}
                hasTitle = {hasTitle}
                customTitle = {sidebarCustomTitle}
                showSidebarCloseButton = {showSidebarCloseButton}
              />
            </CSSTransition>
            {loading && <Loader/>}
            {globalModalState && router.pathname !== '/lobby/[lobbyId]/game/[gameId]' &&
            <>
              {ongoingGame && 
                <CommonSearchingModal
                  type = 'ongoingGame'
                  action = {handleMatchMakingCancel}
                  lobbyData = {ongoingGame}
                  headerActionButtonHandler = {() => {}}
                  hasHeaderActionButton = {true}
                  modalType = {modalType}
                  headerActionButtonType = {'minimize'}
                  secondaryAction = {() => {
                    setOngoingGame(null)
                    setModalTitle(false)
                    setModalType(false)
                    onSetGlobalModalState(false)
                    dispatch(resetOngoingGame())
                  }}
                />

              }
              {!ongoingGame && matchMaking.searching && !lobby.lobbyData && (
                <CommonSearchingModal
                  type = 'searchingGame'
                  action = {handleMatchMakingCancel}
                  matchMaking = {matchMaking}
                  headerActionButtonHandler = {() => {}}
                  hasHeaderActionButton = {true}
                  modalType = {modalType}
                  headerActionButtonType = {'minimize'}
                />
              )}
              {!ongoingGame && lobby.lobbyData && (
                <CommonSearchingModal
                  lobbyData = {lobby.lobbyData}
                  onPlayerJoin = {onPlayerJoin}
                  type = 'gameFound'
                  headerActionButtonHandler = {() => {}}
                  hasHeaderActionButton = {true}
                  modalType = {modalType}
                  headerActionButtonType = {'minimize'}
                  handleGameCouldNotStart = {setGameCouldNotStart}
                />
              )}
          </>              
            }
            <CSSTransition
              // in = {isBackdropOpen || isSpecialThanksOpen}
              in = {isBackdropOpen}
              timeout = {500}
              unmountOnExit
              classNames = 'backdrop'
              >
              <Backdrop
                closeFunc = {() => {
                  onToggleResponsivePopUp({
                    isOpen: false
                  })
                  onToggleBackdropState(false)
                  onToggleSidebar({
                    isOpen: false,
                    type: ''
                  })
                  setIsSpecialThanksOpen(false)
                }}
              />
            </CSSTransition>
            <CSSTransition
              in = {showOnboardingElement && router.pathname === '/'}
              timeout = {500}
              unmountOnExit
              classNames = 'backdrop'
              >
              <Backdrop/>
            </CSSTransition>
            {(isBackdropOpen && sidebarType === 'editAvatar') &&  <AvatarsSlider />}
            {isResponsivePopUpOpen && 
              <ResponsivePopUp 
                closePopUp = {() => {
                  onToggleResponsivePopUp({
                    isOpen: false
                  })
                }}
                type = {responsivePopUpType} 
              />
            }
            {isConfirmModalOpen && (
              <div className = {styles.confirm_modal_container}>
                <ConfirmModal 
                  text = {confirmModalData.text}
                  acceptText = {confirmModalData.acceptText}
                  rejectText = {confirmModalData.rejectText}
                  acceptFunc = {confirmModalData.acceptFunc}
                  rejectFunc = {confirmModalData.rejectFunc}
                  closeFunc = {confirmModalData.closeFunc}
                  showOneButton = {confirmModalData.showOneButton}
                  sameButtons = {confirmModalData.sameButtons}
                  subText = {confirmModalData.subText}
                />
              </div>
            )}
          </>
        {/* )} */}
        {
          isAuthenticated && 
          <CSSTransition
          in = {offlineModalState}
          timeout = {500}
          unmountOnExit
          classNames = 'connection_lost_modal'
        >
            <ConnectionLostModal additionalClickActions = {() => {
                setSocketId(null)
                localStorage.removeItem('socketId')
              }} 
            />
          </CSSTransition>
        }
        {isAuthenticated && showOnboardingElement && router.pathname === '/' && (
          <>
            <div className={styles.onbording_setpper_container}>
              <OnboardingStepper />
            </div>
            <Tooltip gameType={'classic'}/>
          </>
        )}
        {/* <div className={styles.report_button}  onClick={() => {
            window.Usersnap.show(USERSNAP_API_KEY).then((widgetApi) => widgetApi.open());
          }}>
          <button><FeedbackSvg /></button>
        </div> */}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    sidebarState: state.modals.isSidebarOpen,
    sidebarType: state.modals.sidebarType,
    sidebarCustomTitle: state.modals.customTitle,
    sidebarHasBackArrow: state.modals.hasBackArrow,
    callBackSidebarFunction: state.modals.callBackSidebarFunction,
    showSidebarCloseButton: state.modals.showSidebarCloseButton,
    hasTitle: state.modals.hasTitle,
    authMessage: state.auth.message,
    loading: state.auth.isLoading,
    globalModalState: state.modals.globalModalState,
    pusher: state.pusher.pusher,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    lobby: state.lobby,
    matchMaking: state.matchMaking,
    landingType: state.settings.landingType,
    isBackdropOpen: state.backdropState.isOpen,
    isResponsivePopUpOpen: state.modals.isResponsivePopUpOpen,
    responsivePopUpType: state.modals.responsivePopUpType,
    isConfirmModalOpen: state.modals.isConfirmModalOpen,
    confirmModalData: state.modals.confirmModalData,
    socketId: state.pusher.socketId,
    offlineModalState: state.modals.offlineModalState,
    errorsData: state.errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckRequest: () => dispatch(checkAuth()),
    onPusherInstanceCreation: () => dispatch(instantiatePusher()),
    onToggleSidebar: (sidebarState) => dispatch(toggleSidebar(sidebarState)),
    onGetCountriesList: () => dispatch(getCountriesList()),
    onSetGlobalModalState: (modalState) => dispatch(setGlobalModalState(modalState)),
    onGetLobbySuccess: (data) => dispatch(getLobbySuccess(data)),
    onMatchMakingSuccess: (data) => dispatch(matchMakingSuccess(data)),
    onPlayerJoin: (lobbyId) => dispatch(playerJoin(lobbyId)),
    onSetJoinedPlayer: (userId, currentUserId) => dispatch(setJoinedPlayer(userId, currentUserId)),
    onMatchMakingCancel: (mmId) => dispatch(cancelMatchMaking(mmId)),
    onSetNewNotification: (notification) => dispatch(setNewNotification(notification)),
    OnResetSetup: () => dispatch(resetSetup()),
    onGetNotifications: (page, perPage) => dispatch(getNotifications(page, perPage)),
    onGetFriendListRequest: () => dispatch(getFriendList()),
    onGetAvatarList: () => dispatch(getAvatars()),
    onToggleBackdropState: (state) => dispatch(toggleBackdropState(state)),
    onGetGameSetuppData: () => dispatch(getGameSetupData()),
    onSetSocketId: (socketId) => dispatch(setSocketId(socketId)),
    onSendAcceptSuccess: (friend) => dispatch(sendAcceptSuccess(friend)),
    onSendDeleteRequestSuccess: (frienshipId) => dispatch(sendDeleteRequestSuccess(frienshipId)),
    onToggleResponsivePopUp: (payload) => dispatch(toggleResponsivePopUp(payload)),
    onGetSettings: () => dispatch(getSettings()),
    onResetMM: () => dispatch(resetMm()),
    onMarkNotificationAsRead: (payload) => dispatch(markNotificationAsRead(payload)),
    onUpdateFriendOnlineStatus: (payload) => dispatch(updateFriendOnlineStatus(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout); 